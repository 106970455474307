import YouTubePlayer from 'youtube-player';

function getYouTubeId(url) {
    const segments = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (segments[2] !== undefined) {
        return segments[2].split(/[^0-9a-z_-]/i)[0];
    }

    return '';
}

export default function (el, {
    triggerHandle,
    playerHandle,
}) {
    const trigger = el.querySelector(triggerHandle);
    const player = el.querySelector(playerHandle);
    const youtubePlayer = YouTubePlayer(player, {
        playerVars: {
            modestBranding: 1,
            rel: 0,
        },
    });

    function handleTrigger(e) {
        e.preventDefault();

        if (youtubePlayer !== null) {
            youtubePlayer.loadVideoById(getYouTubeId(trigger.href));
            youtubePlayer.playVideo();
        }

        trigger.remove();
    }

    trigger.addEventListener('click', handleTrigger);
}
