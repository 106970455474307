import Swiper from 'swiper';

export default function (el, {
    progressHandle,
}) {
    new Swiper(el, { // eslint-disable-line no-new
        slidesPerView: 'auto',
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 12,
        updateOnWindowResize: true,
        navigation: {
            nextEl: '.next',
            prevEl: '.prev',
        },
        spaceBetween: 12,
        scrollbar: {
            el: progressHandle,
            draggable: true,
        },
        grabCursor: true,
    });
}
