import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default function (el, {
    fixHandle,
    overlayHandle,
    mobileNavToggleHandle,
    mobileMenuToggleHandle,
    searchHandle,
    navsHandle,
    primaryLinkHandle,
    searchActiveClass,
    navActiveClass,
    menuActiveClass,
    fixedClass,
    hiddenClass,
    actions,
    events,
}) {
    const fix = el.querySelector(fixHandle);
    const overlay = el.querySelector(overlayHandle);
    const mobileNavToggle = el.querySelector(mobileNavToggleHandle);
    const navs = el.querySelector(navsHandle);
    const primaryLinks = el.querySelectorAll(primaryLinkHandle);
    const mobileMenuToggles = el.querySelectorAll(mobileMenuToggleHandle);
    const form = el.querySelector(searchHandle);
    const search = form.querySelector('input[type="search"]');
    const submit = form.querySelector('[type="submit"]');

    // Helper functions
    function closeMenus() {
        el.classList.remove(menuActiveClass);
        primaryLinks.forEach(l => {
            l.classList.remove(menuActiveClass);
            l.nextElementSibling.classList.remove(menuActiveClass);
        });
    }

    // Event handler functions
    function handleGetHeaderHeight(e) {
        const { cb } = e.detail;

        cb(primaryLinks[0].offsetHeight);
    }
    function handleShowHeader() {
        el.classList.remove(hiddenClass);
    }
    function handleHideHeader() {
        el.classList.add(hiddenClass);
    }
    function handleFixObserver(entries) {
        el.classList.toggle(fixedClass, entries[0].boundingClientRect.top < 0);
    }
    function handleOverlay() {
        form.classList.remove(searchActiveClass);
        closeMenus();
        clearAllBodyScrollLocks();
    }
    function handleMobileNavToggle() {
        form.classList.remove(searchActiveClass);

        // Close menu if mobile nav is being closed
        if (!el.classList.toggle(navActiveClass)) {
            closeMenus();
            clearAllBodyScrollLocks();
        } else {
            disableBodyScroll(navs);
        }
    }
    const handlePrimaryLinks = Array.from(primaryLinks).map((l, i) => e => {
        e.preventDefault();

        form.classList.remove(searchActiveClass);

        // Close menu if open and clicked on
        if (l.classList.contains(menuActiveClass)) {
            closeMenus();

            if (!el.classList.contains(navActiveClass)) {
                clearAllBodyScrollLocks();
            }

            return;
        }

        el.classList.add(menuActiveClass);
        primaryLinks.forEach((ll, ii) => {
            ll.classList.toggle(menuActiveClass, ii === i);
            if (ll.nextElementSibling.classList.toggle(menuActiveClass, ii === i)) {
                // Make sure navs container at top so nested menu is entriely visible on mobile
                navs.scrollTop = 0;
                disableBodyScroll(ll.nextElementSibling);
            }
        });
    });
    function handleMobileMenuToggle() {
        closeMenus();
    }
    function handleSubmit(e) {
        if (!form.classList.contains(searchActiveClass)) {
            e.preventDefault();

            closeMenus();

            el.classList.add(menuActiveClass);
            form.classList.add(searchActiveClass);
            search.focus();
        }
    }

    // Enable event listeners
    events.on(actions.getHeaderHeight, handleGetHeaderHeight);
    events.on(actions.showHeader, handleShowHeader);
    events.on(actions.hideHeader, handleHideHeader);
    const fixObserver = new IntersectionObserver(handleFixObserver, { threshold: 1 });
    fixObserver.observe(fix);
    overlay.addEventListener('click', handleOverlay);
    mobileNavToggle.addEventListener('click', handleMobileNavToggle);
    primaryLinks.forEach((l, i) => {
        l.addEventListener('click', handlePrimaryLinks[i]);
    });
    mobileMenuToggles.forEach(t => {
        t.addEventListener('click', handleMobileMenuToggle);
    });
    submit.addEventListener('click', handleSubmit);
}
