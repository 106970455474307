export default function (el, {
    activeClass,
    actions,
    events,
}) {
    const cont = el.querySelector('div');

    function handleHideTooltip() {
        el.classList.remove(activeClass);
        el.style.top = 'auto';
        el.style.left = 'auto';
        el.style.right = 'auto';

        document.body.removeEventListener('click', handleHideTooltip);
        window.removeEventListener('scroll', handleHideTooltip);
    }
    function handleShowTooltip(e) {
        const { markup, coords } = e.detail;

        el.classList.add(activeClass);
        el.style.top = coords.top;
        el.style.left = coords.left;
        el.style.right = coords.right;
        cont.innerHTML = markup;

        document.body.addEventListener('click', handleHideTooltip);
        window.addEventListener('scroll', handleHideTooltip);
    }
    function handleContClick(e) {
        e.stopPropagation();
    }

    events.on(actions.showTooltip, handleShowTooltip);
    cont.addEventListener('click', handleContClick);
}
