import Swiper from 'swiper';

export default function (el, {
    progressHandle,
}) {
    new Swiper(el.querySelector('.swiper-container'), // eslint-disable-line no-new
        {
            slidesPerView: 'auto',
            scrollbar: {
                el: progressHandle,
                draggable: true,
            },
        });
}
