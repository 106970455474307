import { router } from 'ui-utilities';

export default function (_, {
    actions,
    events,
}) {
    const intervals = [];
    let interval;

    function handleEventForm(eventForm, percent) {
        const formData = new FormData(eventForm);
        formData.append('event_string', 'Video Percentage Complete');
        formData.append('duration', percent);
        router.post({ formData });
    }

    function updatePercent(videoDuration, currentTime, eventForm) {
        const percent = Math.round((currentTime / videoDuration) * 100);
        if (percent < 25) {
            return;
        }
        if (percent >= 25 && percent < 50) {
            handleEventForm(eventForm, 25);
            return;
        }

        if (percent >= 50 && percent < 75) {
            handleEventForm(eventForm, 50);
            return;
        }

        if (percent >= 75 && percent < 100) {
            handleEventForm(eventForm, 75);
            return;
        }

        handleEventForm(eventForm, 100);
    }

    function handleStartVideoTimer(e) {
        const { target, eventForm } = e.detail;
        const { duration } = target.playerInfo;
        const { video_id: videoId } = target.playerInfo.videoData;

        if (!(videoId in intervals.keys())) {
            const handleTick = () => {
                updatePercent(duration, target.getCurrentTime(), eventForm);
            };
            interval = setInterval(handleTick, 1000);
        }
    }

    function handlePauseVideoTimer() {
        clearInterval(interval);
    }

    events.on(actions.startVideoTimer, handleStartVideoTimer);
    events.on(actions.pauseVideoTimer, handlePauseVideoTimer);
}
