import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { router } from 'ui-utilities';

export default function (el, {
    filtersToggleHandle,
    filterTriggerHandle,
    sortHandle,
    resultsHandle,
    filtersCloseHandle,
    shared,
    actions,
    events,
    refresh,
}) {
    const filtersToggle = el.querySelector(filtersToggleHandle);
    const reset = el.querySelector('[type="reset"]');
    const filterTriggers = el.querySelectorAll(filterTriggerHandle);
    const filtersForm = filterTriggers[0].closest(['form']);
    const search = el.querySelector('[type="search"]');
    const searchFrom = search.closest('form');
    const sort = el.querySelector(sortHandle);
    const results = el.querySelector(resultsHandle);
    const filtersClose = el.querySelector(filtersCloseHandle);

    function handleFiltersToggle() {
        if (filtersToggle.checked) {
            disableBodyScroll();
        } else {
            clearAllBodyScrollLocks();
        }
    }
    function handleReset(e) {
        e.preventDefault();

        // Don't do anything if all filter triggers are unchecked
        if (Array.from(filterTriggers).every(ft => !ft.checked)) {
            return;
        }

        filterTriggers.forEach(ft => { ft.checked = false; });
        filtersForm.insertAdjacentHTML('beforeend', `
                <input type="hidden" name="${search.name}" value="${search.value}" />
                <input type="hidden" name="${sort.name}" value="${sort.value}" />
                <input type="hidden" name="d" value="${filtersToggle.checked ? 'open' : 'closed'}" />
            `);

        events.emit(actions.startLoader);
        filtersForm.submit();
    }
    function handleFilterTrigger() {
        filtersForm.insertAdjacentHTML('beforeend', `
                <input type="hidden" name="${search.name}" value="${search.value}" />
                <input type="hidden" name="${sort.name}" value="${sort.value}" />
                <input type="hidden" name="d" value="${filtersToggle.checked ? 'open' : 'closed'}" />
            `);

        events.emit(actions.startLoader);
        filtersForm.submit();
    }
    function handleSort() {
        const url = shared;
        const formData = new FormData(filtersForm);
        const query = {
            ...Object.fromEntries(formData),
            [search.name]: search.value,
            [sort.name]: sort.value,
            page: 1,
        };
        const hState = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { info, markup } = JSON.parse(res);

            results.innerHTML = markup;
            refresh(results);
            events.emit(actions.stopLoader);
            events.emit(actions.resetPage, { info, shared });
        }

        events.emit(actions.startLoader);
        router.get({ url, query, state: hState, cb });
    }
    function handleClose() {
        filtersToggle.checked = false;
        events.emit(actions.unlockScroll);
    }

    filtersToggle.addEventListener('change', handleFiltersToggle);
    reset.addEventListener('click', handleReset);
    filterTriggers.forEach(t => {
        t.addEventListener('change', handleFilterTrigger);
    });
    searchFrom.addEventListener('submit', handleFilterTrigger);
    sort.addEventListener('change', handleSort);
    filtersClose.addEventListener('click', handleClose);

    // Initialize
    handleFiltersToggle();
}
