export default function (el, {
    imageHandle,
    navHandle,
}) {
    const image = el.querySelector(imageHandle);
    const nav = el.querySelector(navHandle);
    const links = nav.querySelectorAll('a, span');
    const markups = Array.from(links).map(l => l.nextElementSibling.innerHTML);

    const handleHovers = Array.from(links).map((l, i) => e => {
        e.preventDefault();

        image.innerHTML = markups[i];
    });

    links.forEach((l, i) => {
        l.addEventListener('click', handleHovers[i]);
        l.addEventListener('mouseenter', handleHovers[i]);
    });
}
