import { scroll } from 'ui-utilities';

export default function (el, {
    buttonHandle,
    actions,
    events,
}) {
    const button = el.querySelector(`button.${buttonHandle}`);

    function handleClick() {
        events.emit(actions.getHeaderHeight, {
            cb: headerHeight => {
                scroll.bottom(el, headerHeight);
            },
        });
    }

    button.addEventListener('click', handleClick);
}
