import { router } from 'ui-utilities';

export default function (el, {
    commentsHandle,
    videoId,
    userType,
    eventCategory,
    eventAction,
    eventLabel,
    refresh,
    actions,
    events,
}) {
    const form = el.querySelector('form#main-comment-input');
    const textInput = form.querySelector('input[type="text"]');
    const comments = el.querySelector(commentsHandle);

    function getComments() {
        const url = '/shared/comments-list';
        const query = { videoId, userType };

        const cb = res => {
            const { markup } = JSON.parse(res);
            comments.innerHTML = markup;
            refresh(comments);
            events.emit(actions.stopLoader);
        };

        router.get({ url, query, cb });
    }

    // disable send buttons until stuff is in the input boxes
    function handleInputChange(e) {
        const button = e.target.nextElementSibling.querySelector('[type="submit"]');
        button.disabled = !e.target.value.length;
    }

    // comment submit
    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData(form);

        function cb() {
            const input = el.querySelector('#question');
            input.value = '';
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object') {
                // ga('send', 'event', category, action, label);
                dataLayer.push({
                    event: 'huberwoodevents',
                    eventCategory,
                    eventAction,
                    eventLabel,
                });
            }
            /* eslint-enable no-undef */
            getComments();
        }

        events.emit(actions.startLoader);
        router.post({ formData, cb });
    }

    // add event listeners to main input to handle input and clears
    ['input', 'keyup'].map(e => textInput.addEventListener(e, handleInputChange));

    // repeat for each reply input

    form.addEventListener('submit', handleSubmit);
}
