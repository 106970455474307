// Adopt all the little orphans
export default function () {
    const tags = 'h1,h2,h3,h4,h5,h6,p,:not(.ignore-widow-rule)';
    const els = document.querySelectorAll(tags);

    els.forEach(el => {
        const oldWidth = el.scrollWidth;
        // IE11 fix, hopefully
        const ieWidth = el.getBoundingClientRect().width;
        const raw = el.innerHTML;
        const parsed = raw.replace(/\s([^\s<]+)\s*$/, '&nbsp;$1');

        el.innerHTML = parsed;
        if (el.scrollWidth > oldWidth || el.getBoundingClientRect().width > ieWidth) {
            el.innerHTML = raw;
        }
    });
}
