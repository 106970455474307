export default function (el, {
    detailLinkHandle,
    detailHandle,
    openedClass,
}) {
    const links = el.querySelectorAll(detailLinkHandle);
    const details = el.querySelectorAll(detailHandle);
    const triggers = Array.from(details).map(d => d.querySelector('button'));

    const handleLinks = Array.from(links).map((l, i) => () => {
        details[i].classList.add(openedClass);
    });
    const handleTriggers = Array.from(details).map(d => () => {
        d.classList.toggle(openedClass);
    });

    links.forEach((l, i) => {
        l.addEventListener('click', handleLinks[i]);
    });
    triggers.forEach((t, i) => {
        t.addEventListener('click', handleTriggers[i]);
    });
}
