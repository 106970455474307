export default function (el, {
    videoPlayButtonHandle,
    eventAction,
    eventCategory,
    eventLabel,
}) {
    const playButton = el.querySelector(videoPlayButtonHandle);

    playButton.addEventListener('click', () => {
        /* eslint-disable no-undef */
        if (typeof dataLayer === 'object') {
            // ga('send', 'event', category, action, label);
            dataLayer.push({
                event: 'huberwoodevents',
                eventAction,
                eventCategory,
                eventLabel,
            });
        }
        /* eslint-enable no-undef */
    });
}
