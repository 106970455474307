import YouTubePlayer from 'youtube-player';

function getYouTubeId(url) {
    const segments = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (segments[2] !== undefined) {
        return segments[2].split(/[^0-9a-z_-]/i)[0];
    }

    return '';
}

export default function (el, {
    contHandle,
    videoUrl,
    actions,
    events,
}) {
    const cont = el.querySelector(contHandle);
    const player = cont.querySelector('div');
    const youtubePlayer = YouTubePlayer(player, {
        playerVars: {
            modestBranding: 1,
            rel: 0,
        },
    });

    if (youtubePlayer !== null) {
        youtubePlayer.loadVideoById(getYouTubeId(videoUrl));
        youtubePlayer.playVideo();
    }

    function handleCloseModal() {
        if (youtubePlayer !== null) {
            youtubePlayer.pauseVideo();
        }
    }

    events.on(actions.closeModal, handleCloseModal);
}
