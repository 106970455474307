import { router } from 'ui-utilities';

export default function DocumentCard(el, {
    shareButtonHandle,
    shareFormHandle,
    shareMessageHandle,
    closeButtonHandle,
    shareFormActiveClass,
    shareFormSuccessClass,
    documentId,
    documentTitle,
    actions,
    events,
}) {
    const shareButton = el.querySelector(shareButtonHandle);
    const shareForm = el.querySelector(shareFormHandle);
    const shareMessage = el.querySelector(shareMessageHandle);
    const closeButton = el.querySelector(closeButtonHandle);
    const downloadButton = el.querySelector('button[data-modal-trigger]');

    function handleShareSubmit(e) {
        e.preventDefault();

        const formData = new FormData(shareForm);

        function cb(res) {
            const { success, message, errors: errsObject = null } = JSON.parse(res);

            if (!success && errsObject !== null) {
                shareForm.querySelector('h4').innerHTML = message;

                Object.entries(errsObject).forEach(([name, errors]) => {
                    events.emit(actions.showInputErrors, { name, errors, formId: documentId });
                });

                return;
            }
            // Track search event in GA
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object') {
                dataLayer.push({
                    event: 'huberwoodevents',
                    eventCategory: 'Technical Documents',
                    eventAction: 'Submit',
                    eventLabel: `Share Document - ${documentTitle}`,
                });
            }
            /* eslint-enable no-undef */
            // Remove form
            shareForm.removeEventListener('submit', handleShareSubmit);
            shareForm.remove();

            // Show success message
            shareMessage.querySelector('p').innerHTML = message;
            shareMessage.classList.add(shareFormSuccessClass);
        }

        events.emit(actions.getCsrfToken, {
            cb: ({ name, value }) => {
                formData.append(name, value);
                router.post({ formData, cb });
            },
        });
    }

    function handleShareClick() {
        el.classList.add(shareFormActiveClass);
    }
    function handleCloseClick() {
        el.classList.remove(shareFormActiveClass);
    }
    function handleDownload() {
        const url = `${window.location.protocol}//${window.location.hostname}/shared/document`;
        const query = { documentId, documentTitle };

        function cb(res) {
            const { markup } = JSON.parse(res);

            events.emit(actions.loadModal, { markup, size: 'card' });
        }

        router.get({ url, query, cb });
    }

    shareForm.addEventListener('submit', handleShareSubmit);
    shareButton.addEventListener('click', handleShareClick);
    closeButton.addEventListener('click', handleCloseClick);
    if (downloadButton) downloadButton.addEventListener('click', handleDownload);
}
