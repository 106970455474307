export default function (el, {
    errorClass,
    name,
    formId,
    conditionalName,
    conditionalValue,
    actions,
    events,
}) {
    const input = el.querySelector(`[name="${name}"]`);
    const error = el.querySelector('p');
    const form = el.closest('form');
    const targets = conditionalName
        ? form.querySelectorAll(`[name="${conditionalName}"]`)
        : [];

    function toggleVisibility(visible) {
        el.style.display = visible ? 'block' : 'none';
        // Need to hide parent container for form column formatting
        el.parentElement.style.display = visible ? 'block' : 'none';
    }

    function handleShowInputErrors(e) {
        const {
            name: n,
            errors,
            formId: f = '',
        } = e.detail;

        // Don't process input errors for other forms or other inputs
        if (f !== formId || n !== name) {
            return;
        }

        el.classList.toggle(errorClass, errors.length);
        input.classList.toggle(errorClass, errors.length);
        error.innerHTML = errors.join("\n"); // eslint-disable-line quotes
    }
    function handleTargetChange(e) {
        toggleVisibility(e.currentTarget.value === conditionalValue);
    }

    events.on(actions.showInputErrors, handleShowInputErrors);
    targets.forEach(target => {
        target.addEventListener('change', handleTargetChange);
    });

    // Initalize conditional formatting
    if (conditionalName) {
        const formData = new FormData(form);

        toggleVisibility(formData.get(conditionalName) === conditionalValue);
    }
}
