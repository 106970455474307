import { router } from 'ui-utilities';
import { load } from 'recaptcha-v3';

export default function RebateForm(el, {
    recaptchaSiteKey,
    turnstileSiteKey,
    gaSubmissionEvent,
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const submit = form.querySelector('[type="submit"]');
    const closeButton = el.querySelector('button[data-close]');

    if (!form) return;

    let processing = false;

    function clearErrors() {
        const errorContainers = form.querySelectorAll('.error-cont');
        if (!errorContainers.length) {
            return;
        }

        [...errorContainers].forEach(container => { container.style.display = 'none'; });
    }

    function handleErrors(errors) {
        Object.keys(errors).forEach(field => {
            const errorCont = form.querySelector(`[name="${field}"] ~ p`);
            errorCont.innerHTML = errors[[field]];
            errorCont.style.display = 'block';
            errorCont.classList.add('error-cont');
        });
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        load(recaptchaSiteKey).then(r => {
            r.execute('form').then(recaptchaToken => {
                if (processing) {
                    return;
                }

                const formData = new FormData(form);
                formData.set('recaptchaToken', recaptchaToken);

                /* eslint-disable no-undef */
                turnstile.ready(() => {
                    turnstile.render(form, {
                        sitekey: turnstileSiteKey,
                        action: 'rebate',
                        appearance: 'interaction-only',
                        size: 'compact',
                        callback(turnstileToken) {
                            formData.set('turnstileToken', turnstileToken);

                            function cb(res) {
                                processing = false;
                                submit.removeAttribute('disabled');
                                events.emit(actions.stopLoader);

                                const { success, errors = [] } = JSON.parse(res);

                                if (!success) {
                                    handleErrors(errors);

                                    return;
                                }

                                /* eslint-disable no-undef */
                                if (typeof dataLayer === 'object' && gaSubmissionEvent) {
                                    dataLayer.push({
                                        event: 'huberwoodevents',
                                        ...gaSubmissionEvent,
                                    });
                                }
                                /* eslint-enable no-undef */

                                events.emit(actions.startDownload);
                                events.emit(actions.unlockScroll);
                            }

                            clearErrors();
                            processing = true;
                            submit.setAttribute('disabled', true);
                            events.emit(actions.startLoader);
                            router.post({ formData, cb });
                        },
                    });
                });
                /* eslint-enable no-undef */
            });
        });
    }

    form.addEventListener('submit', handleFormSubmit);
    closeButton.addEventListener('click', () => {
        closeButton.parentElement.classList.toggle('show');
        events.emit(actions.unlockScroll);
    });
}
