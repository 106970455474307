import { scroll } from 'ui-utilities';

const IFRAME_MAX_WIDTH = 800;
const SCROLLBAR_HEIGHT = 20;

export default function (el, {
    iframeWrapperHandle,
    actions,
    events,
}) {
    const wrapper = el.querySelector(iframeWrapperHandle);
    const iframe = wrapper.querySelector('iframe');

    let hasLoaded = false;

    function resizeIframe(height) {
        const scale = wrapper.offsetWidth < IFRAME_MAX_WIDTH
            ? wrapper.offsetWidth / IFRAME_MAX_WIDTH
            : 1;

        wrapper.style.height = `${(height * scale) + SCROLLBAR_HEIGHT}px`;
        iframe.style.transform = `scale(${scale})`;
        iframe.style.width = `${100 / scale}%`;
        iframe.style.height = `${100 / scale}%`;
    }

    function handleMessage(e) {
        if (!e.origin.match('https://www.acbpromotions.com')) {
            return;
        }

        resizeIframe(e.data.height || iframe.offsetHeight);
    }
    function handleLoad() {
        if (hasLoaded) {
            events.emit(actions.getHeaderHeight, {
                cb: headerHeight => {
                    scroll.top(iframe, headerHeight);
                },
            });
        }

        hasLoaded = true;
    }

    window.addEventListener('message', handleMessage);
    iframe.addEventListener('load', handleLoad);

    resizeIframe(iframe.offsetHeight);
}
