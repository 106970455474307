import YouTubePlayer from 'youtube-player';
import { router } from 'ui-utilities';

function getYouTubeId(url) {
    const segments = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (segments[2] !== undefined) {
        return segments[2].split(/[^0-9a-z_-]/i)[0];
    }

    return '';
}

export default function (el, {
    imagePlayerHandle,
    videoPlayerHandle,
    mediaItemHandle,
    activeClass,
    playingClass,
    timerDuration,
    disableAutoPause,
    analyticsEvent,
    actions,
    events,
}) {
    const imagePlayer = el.querySelector(imagePlayerHandle);
    const figures = imagePlayer.querySelectorAll('figure');
    const playButtons = imagePlayer.querySelectorAll('button');
    const videoPlayer = el.querySelector(videoPlayerHandle);
    const youtubePlayer = YouTubePlayer(videoPlayer.children[0], {
        playerVars: {
            modestBranding: 1,
            rel: 0,
        },
    });
    const mediaItems = el.querySelectorAll(mediaItemHandle);
    const eventForms = [...mediaItems].map(m => m.querySelector('form')).filter(f => f !== null);
    const watchedVideos = [];

    const pauseThreshold = 0.25;

    // Maintain current timeout request to allow for cancelation of request
    // when new media item clicked
    let timer = null;
    // Need to know if we can play videos yet
    let userInteracted = false;
    let activeIndex = 0;

    // Helper functions
    function setPlayerItem(i) {
        // Clear current state
        window.clearTimeout(timer);
        if (youtubePlayer) {
            youtubePlayer.pauseVideo();
        }
        videoPlayer.classList.remove(activeClass);
        mediaItems.forEach((mi, ii) => {
            mi.classList.toggle(activeClass, i === ii);
        });

        activeIndex = i;

        // Set new state
        const imageSrc = mediaItems[i].getAttribute('data-image-src') || '';
        const videoSrc = mediaItems[i].getAttribute('data-video-src') || '';

        if (userInteracted && videoSrc && youtubePlayer !== null) {
            videoPlayer.classList.add(activeClass);
            youtubePlayer.loadVideoById(getYouTubeId(videoSrc));
            youtubePlayer.playVideo();
            mediaItems.forEach((mi, ii) => { mi.classList.toggle(playingClass, i === ii); });
        } else if (imageSrc) {
            figures.forEach((f, ii) => { f.classList.toggle(activeClass, i === ii); });
            timer = window.setTimeout(() => {
                // Set new index based on bounds of media items
                const next = i + 1 < mediaItems.length ? i + 1 : 0;

                setPlayerItem(next);
            }, timerDuration);
        }
    }

    // Event handler functions
    function handleElObserver(entries) {
        if (!userInteracted) {
            return;
        }

        if (disableAutoPause) {
            return;
        }

        userInteracted = entries[0].intersectionRatio > pauseThreshold;

        setPlayerItem(activeIndex);
    }
    function handleAnalyticsEvent() {
        /* eslint-disable no-undef */
        if (typeof dataLayer === 'object' && analyticsEvent) {
            dataLayer.push({
                event: 'huberwoodevents',
                eventCategory: analyticsEvent.category,
                eventAction: analyticsEvent.action,
                eventLabel: analyticsEvent.label,
            });
            /* eslint-enable no-undef */
        }
    }

    function handleEventForm(eventForm, duration = '0') {
        const formData = new FormData(eventForm);
        const videoUrl = formData.get('video_id');
        if (!watchedVideos.includes(videoUrl)) {
            formData.append('event_string', 'Video Started');
            formData.append('duration', duration);
            router.post({ formData });
            if (duration === '0') {
                handleAnalyticsEvent();
            }
            watchedVideos.push(videoUrl);
        }
    }

    function handleVideoState(e) {
        const { target } = e;
        const eventForm = eventForms[activeIndex];
        // Video ended
        if (e.data === 0 && mediaItems.length) {
            // Get current index based on active class of media items
            const curr = Array.from(mediaItems)
                .findIndex(m => m.classList.contains(activeClass));
                // Set new index based on bounds of media items
            const next = curr + 1 < mediaItems.length ? curr + 1 : 0;

            if (eventForm) {
                handleEventForm(eventForm, 100);
            }
            setPlayerItem(next);
        }
        if (e.data === 1 && mediaItems.length) {
            // Get current form associated with active media item
            if (eventForm) {
                handleEventForm(eventForm);
                events.emit(actions.startVideoTimer, { target, eventForm });
            } else {
                handleAnalyticsEvent();
            }
        }

        if (e.data === 2 && mediaItems.length) {
            events.emit(actions.pauseVideoTimer);
        }
    }
    const handleMediaItems = Array.from(mediaItems).map((mi, i) => () => {
        userInteracted = true;

        setPlayerItem(i);
    });

    // Add event listeners
    const elObserver = new IntersectionObserver(handleElObserver, {
        threshold: pauseThreshold,
    });
    elObserver.observe(el);
    youtubePlayer.on('stateChange', handleVideoState);
    if (playButtons) {
        playButtons.forEach(pb => {
            const i = pb.getAttribute('data-index');

            pb.addEventListener('click', handleMediaItems[i]);
        });
    }
    if (mediaItems.length) {
        mediaItems.forEach((mi, i) => {
            mi.addEventListener('click', handleMediaItems[i]);
        });
    }

    // Initialize
    if (mediaItems.length) {
        setPlayerItem(0);
    }
}
