import { router } from 'ui-utilities';
import { format } from 'timeago.js';

export default function (el, {
    commentsHandle,
    videoId,
    userType,
    refresh,
    actions,
    events,
}) {
    const comments = el.querySelector(commentsHandle);
    const timestamps = el.querySelectorAll('[data-timestamp]');
    const replyForms = el.querySelectorAll('form[data-reply]');
    const replyInputs = el.querySelectorAll('[name="reply"]');
    const deleteForms = el.querySelectorAll('.delete-message');

    function getComments() {
        const url = '/shared/comments-list';
        const query = { videoId, userType };

        const cb = res => {
            const { markup } = JSON.parse(res);
            comments.innerHTML = markup;
            refresh(comments);
            events.emit(actions.stopLoader);
        };

        router.get({ url, query, cb });
    }

    // disable send buttons until stuff is in the input boxes
    function handleInputChange(e) {
        const button = e.target.nextElementSibling.querySelector('[type="submit"]');
        button.disabled = !e.target.value.length;
    }

    // comment delete
    function handleDelete(e) {
        e.preventDefault();

        const formData = new FormData(e.target);

        function cb(res) {
            getComments();
        }

        if (confirm('Are you sure you want to delete this comment? This cannot be undone.')) {
            events.emit(actions.startLoader);
            router.post({ formData, cb });
        }
    }

    // reply submit
    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        function cb(res) {
            getComments();
        }

        events.emit(actions.startLoader);
        router.post({ formData, cb });
    }

    [...timestamps].map(t => {
        const date = new Date(t.dataset.timestamp);
        const words = format(date);
        t.innerHTML = words;
    });

    // add 2 event listeners to each input to handle enabling the submit button
    [...replyInputs].map(input => {
        ['input', 'keyup'].map(e => input.addEventListener(e, handleInputChange));
    });

    [...replyForms].map(form => form.addEventListener('submit', handleSubmit));
    [...deleteForms].map(form => form.addEventListener('submit', handleDelete));
}
