import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { router, scroll } from 'ui-utilities';

export default function (el, {
    contentHandle,
    actions,
    events,
}) {
    // Elements and class variables
    const content = el.querySelector(contentHandle);

    // Event handler functions
    function handleLockScroll() {
        disableBodyScroll();
    }
    function handleUnlockScroll() {
        clearAllBodyScrollLocks();
    }
    function handleShowOverlay() {
        // Allow children of content to specify their own z-index to overlay header/footer
        content.style.zIndex = 'initial';
    }
    function handleHideOverlay() {
        content.style.zIndex = '';
    }
    function handleResetPage() {
        const anchors = el.querySelectorAll('a[href*="#"]:not([target="_blank"]):not([data-ready="true"]):not(.footnote)');
        const videos = el.querySelectorAll('a[href*="youtube"]:not([data-ready="true"]), a[href*="youtu.be"]:not([data-ready="true"])');

        function handleVideo(e) {
            const a = e.currentTarget;

            // Allow definer of links to passthrough modal functionality
            if (a.getAttribute('data-passthrough')) {
                return;
            }

            e.preventDefault();

            const url = `${window.location.protocol}//${window.location.hostname}/shared/video`;
            const query = {
                videoUrl: a.href,
            };

            function cb(res) {
                const { markup } = JSON.parse(res);

                events.emit(actions.loadModal, { markup, size: 'video' });
            }

            router.get({ url, query, cb });
        }
        function handleAnchor(e) {
            e.preventDefault();

            const a = e.currentTarget;

            events.emit(actions.getHeaderHeight, {
                cb: headerHeight => {
                    scroll.to(a.href.split('#')[1], headerHeight);
                },
            });
        }

        // Only attach listener if anchor link has corresponding section
        Array.from(anchors)
            .filter(a => document.getElementById(a.href.split('#')[1]))
            .forEach(a => {
                a.addEventListener('click', handleAnchor);
                a.setAttribute('data-ready', true);
            });
        videos.forEach(v => {
            v.addEventListener('click', handleVideo);
            v.setAttribute('data-ready', true);
        });
    }
    function handleGetCsrfToken(e) {
        const { cb } = e.detail;

        // Fetch CSRF token for static forms to make post request
        router.get({
            url: `${window.location.protocol}//${window.location.hostname}/query/csrf/token`,
            cb: res => { cb(JSON.parse(res)); },
        });
    }

    // Add event listeners
    events.on(actions.lockScroll, handleLockScroll);
    events.on(actions.unlockScroll, handleUnlockScroll);
    events.on(actions.showOverlay, handleShowOverlay);
    events.on(actions.hideOverlay, handleHideOverlay);
    events.on(actions.resetPage, handleResetPage);
    events.on(actions.lockScroll, handleLockScroll);
    events.on(actions.getCsrfToken, handleGetCsrfToken);

    // Initialize
    handleResetPage();
}
