export default function (el, {
    activeClass,
}) {
    const triggers = el.querySelectorAll('nav a');

    let timer = null;

    // Helper functions
    function setActiveItem(i) {
        window.clearTimeout(timer);

        triggers.forEach((tt, ii) => {
            tt.classList.toggle(activeClass, i === ii);
            // Activate sibling background image
            tt.nextElementSibling.classList.toggle(activeClass, i === ii);
        });

        timer = window.setTimeout(() => {
            // Set new index based on bounds of triggers
            const next = i + 1 < triggers.length ? i + 1 : 0;

            setActiveItem(next);
        }, 5000);
    }

    const handleTriggers = Array.from(triggers).map((t, i) => () => {
        setActiveItem(i);
    });

    // Add event listeners
    Array.from(triggers).forEach((t, i) => {
        t.addEventListener('mouseenter', handleTriggers[i]);
    });

    // Initialize
    setActiveItem(0);
}
