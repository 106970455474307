import { router } from 'ui-utilities';

export default function (el, {
    resultsHandle,
    registrationCardsHandle,
    shared,
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const dropdowns = form.querySelectorAll('[type="checkbox"]');
    const filters = form.querySelectorAll('input[type="radio"]');
    const reset = form.querySelector('[type="reset"]');
    const results = el.querySelector(resultsHandle);
    const videoLinks = el.querySelectorAll('.stats-cont > a[data-slug]');
    const registrationCards = el.querySelector(registrationCardsHandle);
    const closeButtons = registrationCards.querySelectorAll('button[data-close]');

    const getEvents = () => {
        const url = '/shared/listings/hbcr-videos';
        const formData = new FormData(form);
        const query = {
            ...Object.fromEntries(formData.entries()),
            page: 1,
        };
        const cb = res => {
            const { markup, info } = JSON.parse(res);

            results.innerHTML = markup;
            events.emit(actions.resetPage, { info, shared });
        };

        router.get({ url, query, cb });
    };

    const handleFilter = () => {
        dropdowns.forEach(d => { d.checked = false; });
        getEvents();
    };
    const handleReset = () => {
        filters.forEach(f => { f.checked = false; });
        getEvents();
    };

    const handleVideoClick = e => {
        e.preventDefault();
        registrationCards.querySelector(`#${e.target.parentElement.dataset.slug}`).classList.add('show');
        document.body.style.overflowY = 'hidden'; // prevent scrolling
    };

    filters.forEach(f => {
        f.addEventListener('change', handleFilter);
    });
    reset.addEventListener('click', handleReset);

    videoLinks.forEach(link => { link.addEventListener('click', handleVideoClick); });
    closeButtons.forEach(btn => {
        btn.addEventListener('click', () => {
            btn.parentElement.classList.toggle('show');
            document.body.style.overflowY = 'auto'; // prevent scrolling
        });
    });
}
