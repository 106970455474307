import { router, scroll } from 'ui-utilities';

export default function (el, {
    filterTriggerHandle,
    headingHandle,
    resultsHandle,
    shared,
    subjects = ['Item', 'Items'],
    actions,
    events,
    refresh,
}) {
    const search = el.querySelector('[type="search"]');
    const form = search.closest('form');
    const filterTriggers = el.querySelectorAll(filterTriggerHandle);
    const heading = el.querySelector(headingHandle);
    const results = el.querySelector(resultsHandle);
    const sort = Array.from(filterTriggers).find(t => t.getAttribute('name') === 'sort');

    function handleSubmit(e) {
        e.preventDefault();

        const url = shared;
        const formData = new FormData(form);
        const query = {
            ...Object.fromEntries(formData.entries()),
            sort: sort.value,
            page: 1,
        };
        const hState = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { info, markup } = JSON.parse(res);
            const { first, last, total } = info;

            heading.innerHTML = `
                    ${first} to ${last} of
                    ${total} ${parseInt(total, 10) === 1 ? subjects[0] : subjects[1]}
                    ${search.value !== '' ? `for "${search.value}"` : ''}
                `.trim();
            results.innerHTML = markup;
            refresh(results);
            events.emit(actions.stopLoader);
            events.emit(actions.resetPage, { info, shared });
        }

        events.emit(actions.getHeaderHeight, {
            cb: headerHeight => {
                scroll.top(el, headerHeight);
                events.emit(actions.startLoader);
                router.get({ url, query, state: hState, cb });
            },
        });
    }

    form.addEventListener('submit', handleSubmit);
    filterTriggers.forEach(t => {
        t.addEventListener('change', handleSubmit);
    });
}
