import { router } from 'ui-utilities';

export default function (el, {
    listingsHandle,
    moreHandle,
    shared,
    params = {},
    page = 0,
    actions,
    events,
    refresh,
    useState = true,
}) {
    const listings = el.querySelector(listingsHandle);
    const more = el.querySelector(moreHandle);

    function setMoreState(lastPage) {
        if (lastPage) {
            more.setAttribute('disabled', true);
        } else {
            more.removeAttribute('disabled');
        }
    }

    function handleResetPage(e) {
        const { info, shared: s } = e.detail;
        if (s !== shared) {
            return;
        }
        page = info.currentPage;

        setMoreState(info.currentPage === info.totalPages);
    }
    function handleMore(e) {
        e.preventDefault();

        if (more.getAttribute('disabled')) {
            return;
        }

        page++;

        const url = shared;
        const urlParams = new URLSearchParams(window.location.search);
        const query = {
            ...params, // Base parameters from template
            ...Object.fromEntries(urlParams.entries()), // Current parameters from URL
            page,
        };

        const state = useState ? {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        } : {};

        function cb(res) {
            const { info, markup } = JSON.parse(res);

            listings.insertAdjacentHTML('beforeend', markup);
            refresh(listings);
            events.emit(actions.resetPage, { info, shared });
        }

        router.get({ url, query, state, cb });
    }

    events.on(actions.resetPage, handleResetPage);
    more.addEventListener('click', handleMore);
}
