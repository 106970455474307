const ACTIVE_ATTR = 'data-active-tooltip';

export default function ({
    actions,
    events,
}) {
    // Enable any links w/ footnote class to show tooltip
    const links = document.querySelectorAll('a.footnote');

    function handleFootnoteLink(e) {
        e.preventDefault();
        e.stopPropagation();

        const link = e.currentTarget;

        if (link.getAttribute(ACTIVE_ATTR)) {
            return;
        }

        const footnote = document.getElementById(link.href.split('#')[1]);

        if (footnote === null) {
            return;
        }

        const markup = footnote.innerHTML;
        // Use position of link to determine placement of tooltip
        const rect = link.getBoundingClientRect();
        const x = rect.x || rect.left; // Fallback for IE
        const y = rect.y || rect.top; // Fallback for IE
        const coords = {
            top: `${y}px`,
            left: x < (window.innerWidth / 2) ? `calc(${x}px + 1em)` : 'auto',
            right: x < (window.innerWidth / 2) ? 'auto' : `calc(${window.innerWidth - x}px - 1em)`,
        };

        // Show tooltip over footnote
        events.emit(actions.showTooltip, { markup, coords });
    }

    links.forEach(l => {
        l.addEventListener('mouseenter', handleFootnoteLink);
        l.addEventListener('click', handleFootnoteLink);
    });
}
