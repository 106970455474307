export default function (el, {
    ctaLink,
}) {
    const button = el.querySelector('#cta-button');
    const upcomingEvents = document.querySelector(ctaLink);

    function handleClick(e) {
        e.preventDefault();

        window.scroll({
            top: upcomingEvents.offsetTop,
            behavior: 'smooth',
        });
    }

    button.addEventListener('click', handleClick);
}
