import { load } from 'recaptcha-v3';

const getTokenInput = (name, value) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value);

    return input;
};

export default function Form(el, {
    modelHandle,
    recaptchaSiteKey,
    turnstileSiteKey,
}) {
    const form = el.querySelector('form');

    if (!form) return;

    const submit = form.querySelector('[type="submit"]');

    let processing = false;

    // Load reCAPTCHA object from key
    function handleSubmit(e) {
        e.preventDefault();

        if (processing) {
            return;
        }

        processing = true;
        submit.setAttribute('disabled', true);

        load(recaptchaSiteKey).then(r => {
            r.execute('form').then(recaptchaToken => {
                form.appendChild(getTokenInput('recaptchaToken', recaptchaToken));

                /* eslint-disable no-undef */
                turnstile.ready(() => {
                    turnstile.render(form, {
                        sitekey: turnstileSiteKey,
                        action: modelHandle,
                        appearance: 'interaction-only',
                        size: 'compact',
                        callback(turnstileToken) {
                            form.appendChild(getTokenInput('turnstileToken', turnstileToken));
                            form.submit();
                        },
                    });
                });
                /* eslint-enable no-undef */
            });
        });
    }

    form.addEventListener('submit', handleSubmit);
}
