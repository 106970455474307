export default function (el, {
    progressHandle,
}) {
    const progress = el.querySelector(progressHandle);

    // Event handler functions
    function handleScroll() {
        const width = (window.pageYOffset + window.innerHeight) / document.body.offsetHeight;

        progress.style.transform = `scaleX(${width})`;
    }

    // Add event listeners
    window.addEventListener('scroll', handleScroll);
}
