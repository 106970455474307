export default function (el, {
    requireContactDetails,
    actions,
    events,
}) {
    const formCont = el.querySelector('.rebate-form');
    const downloadForm = el.querySelector('#download-form');
    const trigger = el.querySelector('.trigger');

    const startDownload = () => {
        downloadForm.submit();
        formCont.classList.toggle('show');
    };

    const handleButtonClick = e => {
        if (!requireContactDetails) {
            return;
        }
        e.preventDefault();
        formCont.classList.add('show');
        events.emit(actions.lockScroll);
    };

    trigger.addEventListener('click', handleButtonClick);
    events.on(actions.startDownload, startDownload);
}
