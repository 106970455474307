export default function (el, {
    loadingClass,
    actions,
    events,
}) {
    // Event handler functions
    function handleStartLoader() {
        el.classList.add(loadingClass);
    }
    function handleStopLoader() {
        el.classList.remove(loadingClass);
    }

    // Add event listeners
    events.on(actions.startLoader, handleStartLoader);
    events.on(actions.stopLoader, handleStopLoader);
}
