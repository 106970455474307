import Swiper from 'swiper';

export default function (el, {
    contHandle,
    prevHandle,
    nextHandle,
}) {
    const cont = el.querySelector(contHandle);
    const prev = el.querySelector(prevHandle);
    const next = el.querySelector(nextHandle);

    // Initialize slider
    new Swiper(cont, { // eslint-disable-line no-new
        loop: true,
        slidesPerView: 'auto',
        navigation: {
            prevEl: prev,
            nextEl: next,
        },
    });
}
