import { load } from 'recaptcha-v3';
import { router } from 'ui-utilities';

export default function RegistrationCard(el, {
    recaptchaSiteKey,
    turnstileSiteKey,
    actions,
    events,
}) {
    const form = el.querySelector('form');

    const submit = form.querySelector('[type="submit"]');

    let processing = false;

    function handleSubmit(e) {
        e.preventDefault();

        if (processing) {
            return;
        }

        processing = true;
        submit.setAttribute('disabled', true);

        const formData = new FormData();
        formData.append('action', 'forms-module/forms/validate');

        load(recaptchaSiteKey).then(r => {
            r.execute('form').then(recaptchaToken => {
                formData.append('recaptchaToken', recaptchaToken);

                /* eslint-disable no-undef */
                turnstile.ready(() => {
                    turnstile.render(form, {
                        sitekey: turnstileSiteKey,
                        action: 'registration',
                        appearance: 'interaction-only',
                        size: 'compact',
                        callback(turnstileToken) {
                            formData.append('turnstileToken', turnstileToken);
                            events.emit(actions.getCsrfToken, {
                                cb: ({ name, value }) => {
                                    formData.append(name, value);
                                    router.post({
                                        formData,
                                        cb: res => {
                                            const { success, recaptcha, turnstile } = JSON.parse(res);

                                            processing = false;
                                            submit.removeAttribute('disabled');

                                            if (!success) {
                                                window.alert('validation failed. Please try again.');
                                                console.log(`recaptcha: ${recaptcha}, turnstile: ${turnstile}`);

                                                return;
                                            }

                                            form.insertAdjacentHTML('afterbegin', '<input type="hidden" name="action" value="users/save-user" />');
                                            form.insertAdjacentHTML('afterbegin', `<input type="hidden" name="${name}" value="${value}" />`);
                                            form.submit();
                                        },
                                    });
                                },
                            });
                        },
                    });
                });
            });
        });
    }

    form.addEventListener('submit', handleSubmit);
}
