import Swiper from 'swiper';

export default function (el, {
    progressHandle,
}) {
    // Initialize slider
    new Swiper(el, { // eslint-disable-line no-new
        slidesPerView: 'auto',
        scrollbar: {
            el: progressHandle,
            draggable: true,
        },
    });
}
