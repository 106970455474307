import { router } from 'ui-utilities';
import stateList from '../helpers/quadrant-list.json';

export default function (el, {
    resultsHandle,
    showCountiesClass,
    searchClass,
    shared,
    refresh,
}) {
    const form = el.querySelector('form');
    const [stateSelect, countySelect] = form.querySelectorAll('select');
    const results = el.querySelector(resultsHandle);

    function getSalesManagers() {
        const url = shared;
        const formData = new FormData(form);
        const query = Object.fromEntries(formData.entries());
        const state = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { markup } = JSON.parse(res);

            results.innerHTML = markup;
            refresh(results);

            el.classList.add(searchClass);

            // Track search event in GA
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object') {
                dataLayer.push({
                    event: 'huberwoodevents',
                    eventCategory: 'Contact Us',
                    eventAction: 'Submit',
                    eventLabel: 'Searched For Local Sales Manager - Submitted',
                });
            }
            /* eslint-enable no-undef */
        }

        router.get({ url, query, state, cb });
    }

    function handleStateSelectChange() {
        const url = `${window.location.protocol}//${window.location.hostname}/shared/counties`;
        const query = { state: stateSelect.value };
        const state = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { counties } = JSON.parse(res);

            // Populate county select w/ options
            countySelect.innerHTML = counties
                .map(row => `<option value="${row.value}">${row.label}</option>`)
                .join('');

            el.classList.add(showCountiesClass);
            el.classList.remove(searchClass);
        }

        router.get({ url, query, state, cb });
    }
    function handleCountySelectChange() {
        const selectedState = stateSelect.value;
        Array.from(el.querySelectorAll('.background-image')).forEach(div => { div.style.backgroundPosition = stateList[selectedState]; });

        getSalesManagers();
    }

    stateSelect.addEventListener('change', handleStateSelectChange);
    countySelect.addEventListener('change', handleCountySelectChange);
}
