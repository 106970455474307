import { router, scroll } from 'ui-utilities';

export default function (el, {
    resultsHandle,
    emptyClass,
    searchClass,
    shared,
    actions,
    events,
    refresh,
}) {
    const form = el.querySelector('form');
    const selects = form.querySelectorAll('select');
    const results = el.querySelector(resultsHandle);

    function handleSubmit(e) {
        e.preventDefault();

        const url = shared;
        const formData = new FormData(form);
        const query = Object.fromEntries(formData.entries());
        const state = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { count, markup } = JSON.parse(res);

            el.classList.toggle(emptyClass, count === 0);
            el.classList.add(searchClass);

            results.innerHTML = markup;
            refresh(results);
            events.emit(actions.stopLoader);

            // Track search event in GA
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object') {
                dataLayer.push({
                    event: 'huberwoodevents',
                    eventCategory: 'Where to Buy',
                    eventAction: 'Submit',
                    eventLabel: `Search Clicked - ${query.zip}`,
                });
            }
            /* eslint-enable no-undef */
        }

        events.emit(actions.startLoader);
        scroll.top(results, 200);
        router.get({ url, query, state, cb });
    }

    form.addEventListener('submit', handleSubmit);
    selects.forEach(s => {
        s.addEventListener('change', handleSubmit);
    });
}
