import { scroll } from 'ui-utilities';

export default function (el, {
    actions,
    events,
}) {
    const filtersToggle = el.querySelector('input[type="checkbox"]');
    const filtersToggleLabel = filtersToggle.nextElementSibling;
    const filters = el.querySelectorAll('input[type="radio"]');
    const downloads = el.querySelectorAll('article');

    const handleFilters = Array.from(filters).map(f => () => {
        filtersToggle.checked = false;
        filtersToggleLabel.innerHTML = `
                Filter by:
                <strong>${f.nextElementSibling.textContent}</strong>
                <em><span></span><span></span></em>
            `;
        events.emit(actions.getHeaderHeight, {
            cb: headerHeight => {
                scroll.top(filtersToggle.closest('div'), headerHeight);
                downloads.forEach(d => {
                    if (f.value === '') {
                        d.style.display = 'block';

                        return;
                    }

                    d.style.display = d.getAttribute('data-filters').split(',').includes(f.value)
                        ? 'block'
                        : 'none';
                });
            },
        });
    });

    filters.forEach((f, i) => {
        f.addEventListener('change', handleFilters[i]);
    });
}
