import { router, scroll } from 'ui-utilities';

export default function (el, {
    locationFormHandle,
    exacorFormHandle,
    resultsHeadingOptions,
    resultsHeadingHandle,
    resultsListingHandle,
    emptyClass,
    searchClass,
    shared,
    actions,
    events,
    refresh,
}) {
    const locationForm = el.querySelector(locationFormHandle);
    const exacorForm = el.querySelector(exacorFormHandle);
    const locationFormSelects = locationForm.querySelectorAll('select');
    const resultsHeading = el.querySelector(resultsHeadingHandle);
    const resultsListing = el.querySelector(resultsListingHandle);

    function handleSubmit(query, eventLabel, resultsHeadingText) {
        const url = shared;
        const state = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { count, markup } = JSON.parse(res);

            el.classList.toggle(emptyClass, count === 0);
            el.classList.add(searchClass);

            resultsHeading.innerHTML = resultsHeadingText;
            resultsListing.innerHTML = markup;
            refresh(resultsHeading);
            refresh(resultsListing);
            events.emit(actions.stopLoader);

            // Track search event in GA
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object') {
                dataLayer.push({
                    event: 'huberwoodevents',
                    eventCategory: 'Where to Buy',
                    eventAction: 'Submit',
                    eventLabel,
                });
            }
            /* eslint-enable no-undef */
        }

        events.emit(actions.startLoader);
        scroll.top(resultsListing, 200);
        router.get({ url, query, state, cb });
    }

    function handleLocationFormSubmit(e) {
        e.preventDefault();

        const formData = new FormData(locationForm);
        const query = Object.fromEntries(formData.entries());
        const eventLabel = `Search Clicked - ${query.zip}`;
        const resultsHeadingText = resultsHeadingOptions[0];

        handleSubmit(query, eventLabel, resultsHeadingText);
    }
    function handleExacorFormSubmit(e) {
        e.preventDefault();

        // reset the location form in case the user submitted it
        // prior to the exacor form - just a nicety to avoid confusion
        locationForm.reset();

        const formData = new FormData(exacorForm);
        const query = Object.fromEntries(formData.entries());
        const eventLabel = 'Search Clicked - EXACOR';
        const resultsHeadingText = resultsHeadingOptions[1];

        handleSubmit(query, eventLabel, resultsHeadingText);
    }

    // Event Listeners
    locationForm.addEventListener('submit', handleLocationFormSubmit);
    locationFormSelects.forEach(s => {
        s.addEventListener('change', handleLocationFormSubmit);
    });

    exacorForm.addEventListener('submit', handleExacorFormSubmit);
}
