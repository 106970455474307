export default function () {
    // Enable any links w/ event categories defined on data attributes
    const links = document.querySelectorAll('a[data-event-category]');
    // Enable any links to the support number
    const tels = document.querySelectorAll('a[href^="tel:"][href$="9220"]');

    // Merge links and tels to add event listeners to all
    const els = Array.from(links).concat(Array.from(tels));

    function handleTrigger(e) {
        const el = e.currentTarget;
        const category = el.getAttribute('data-event-category') || 'Page Engagement';
        const action = el.getAttribute('data-event-action') || 'Click';
        const label = el.getAttribute('data-event-label') || el.getAttribute('href');
        const brandZip = el.getAttribute('data-event-brand-zip') || '';
        const brandAdvantech = el.getAttribute('data-event-brand-advantech') || '';
        const brandExacor = el.getAttribute('data-event-brand-exacor') || '';
        const brandHew = el.getAttribute('data-event-brand-hew') || '';
        const engagementType = el.getAttribute('data-event-engagement-type') || '';
        const funnelStage = el.getAttribute('data-event-funnel-stage') || '';

        /* eslint-disable no-undef */
        if (typeof dataLayer === 'object') {
            dataLayer.push({
                event: 'huberwoodevents',
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                brandZip,
                brandAdvantech,
                brandExacor,
                brandHew,
                engagementType,
                funnelStage,
            });
        }
        /* eslint-enable no-undef */
    }

    els.forEach(el => {
        el.addEventListener('click', handleTrigger);
    });
    // TODO: Set event payload properties for support phone links
    tels.forEach(t => {
        t.setAttribute('data-event-category', 'Contact Us');
        t.setAttribute('data-event-action', 'Click to Call');
        t.setAttribute('data-event-label', 'Customer Support - 1.800.933.9220');
        t.addEventListener('click', handleTrigger);
    });

    // Add client ID form tracker to cookies for use in server side tracking of document downloads
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#getClientId
    /* eslint-disable no-undef */
    if (typeof ga === 'function') {
        ga(tracker => {
            document.cookie = `cid=${tracker.get('clientId')}`;
        });
    }
    /* eslint-enable no-undef */
}
