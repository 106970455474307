import { router } from 'ui-utilities';

export default function (el, {
    resultsHandle,
    shared,
    actions,
    events,
}) {
    const form = el.querySelector('form');
    const dropdowns = form.querySelectorAll('[type="checkbox"]');
    const filters = form.querySelectorAll('input[type="radio"]');
    const reset = form.querySelector('[type="reset"]');
    const results = el.querySelector(resultsHandle);

    const getEvents = () => {
        const url = '/shared/listings/hbcr-events';
        const formData = new FormData(form);
        const query = {
            ...Object.fromEntries(formData.entries()),
            page: 1,
        };
        const cb = res => {
            const { markup, info } = JSON.parse(res);

            results.innerHTML = markup;
            events.emit(actions.resetPage, { info, shared });
        };

        router.get({ url, query, cb });
    };

    const handleFilter = () => {
        dropdowns.forEach(d => { d.checked = false; });
        getEvents();
    };
    const handleReset = () => {
        filters.forEach(f => { f.checked = false; });
        getEvents();
    };

    filters.forEach(f => {
        f.addEventListener('change', handleFilter);
    });
    reset.addEventListener('click', handleReset);
}
