import { router } from 'ui-utilities';

export default function (el, {
    resultsHandle,
    activeClass,
    type,
    page = 1,
    shared,
    actions,
    events,
    refresh,
}) {
    const form = el.querySelector('form');
    const heading = el.querySelector('h2');
    const typeLinks = el.querySelectorAll('nav a');
    const results = el.querySelector(resultsHandle);

    function getSearchResults() {
        const url = shared;
        const formData = new FormData(form);
        const query = {
            ...Object.fromEntries(formData.entries()),
            type,
            page,
        };
        const state = {
            url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
            query,
        };

        function cb(res) {
            const { info, markup } = JSON.parse(res);
            const { first, last, total } = info;
            const search = formData.get('q').trim();

            // Set heading
            heading.innerHTML = `
                    ${first} to ${last} of
                    ${total} ${total === 1 ? 'Results' : 'Results'}
                    ${search !== '' ? `for "${search}"` : ''}
                `.trim();
            // Add results
            results.innerHTML = markup;
            refresh(results);
            // Reset page of loadable listings component
            events.emit(actions.resetPage, { info, shared });
        }

        router.get({ url, query, state, cb });
    }

    function handleSubmit(e) {
        e.preventDefault();

        page = 1;

        getSearchResults();
    }
    const handleTypeLinks = Array.from(typeLinks).map((l, i) => e => {
        e.preventDefault();

        type = l.getAttribute('data-value');
        page = 1;

        typeLinks.forEach((tt, ii) => tt.classList.toggle(activeClass, i === ii));
        getSearchResults();
    });

    form.addEventListener('submit', handleSubmit);
    typeLinks.forEach((l, i) => {
        l.addEventListener('click', handleTypeLinks[i]);
    });
}
