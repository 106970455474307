import Swiper from 'swiper';

export default function (el, {
    headingCarouselHandle,
    quotesCarouselHandle,
    bgCarouselHandle,
    controlsPrevHandle,
    controlsNextHandle,
}) {
    const headingCarousel = el.querySelector(headingCarouselHandle);
    const quotesCarousel = el.querySelector(quotesCarouselHandle);
    const bgCarousel = el.querySelector(bgCarouselHandle);
    const prevControl = el.querySelector(controlsPrevHandle);
    const nextControl = el.querySelector(controlsNextHandle);
    const timeout = 8000; // coordinate with quotes-carousel.scss

    const config = {
        init: false,
        autoplay: {
            delay: timeout,
            disableOnInteraction: true,
        },
        allowTouchMove: false,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            prevEl: prevControl,
            nextEl: nextControl,
        },
    };

    const headingSwiper = new Swiper(headingCarousel, config);
    const quotesSwiper = new Swiper(quotesCarousel, config);
    const bgSwiper = new Swiper(bgCarousel, config);

    // Initialize Swiper
    headingSwiper.init();
    quotesSwiper.init();
    bgSwiper.init();
}
