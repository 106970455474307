import { scroll, throttle } from 'ui-utilities';

export default function (el, {
    activeClass,
    actions,
    events,
}) {
    const list = el.querySelector('ul');

    if (!list) {
        return;
    }

    const images = list.querySelectorAll('img');
    const trigger = el.querySelector('button');

    if (!trigger) {
        return;
    }

    function setListHeight() {
        list.style.maxHeight = `${list.children[0].offsetHeight + 24}px`;
    }
    function collapse() {
        el.classList.remove(activeClass);
        setListHeight();
    }

    const handleResize = throttle(() => {
        if (el.classList.contains(activeClass)) {
            return;
        }

        setListHeight();
    }, 500);
    function handleTrigger() {
        if (el.classList.toggle(activeClass)) {
            // Get height difference between first top and last bottom
            const firstTop = list.children[0].getBoundingClientRect().top;
            const lastBottom = list.children[list.children.length - 1].getBoundingClientRect().bottom; // eslint-disable-line max-len

            list.style.maxHeight = `${lastBottom - firstTop}px`;
        } else {
            events.emit(actions.getHeaderHeight, {
                cb: headerHeight => {
                    scroll.top(el, headerHeight, collapse);
                },
            });
        }
    }

    window.addEventListener('resize', handleResize);
    trigger.addEventListener('click', handleTrigger);
    // Trigger collapse after initial images load
    Array.from(images).slice(0, 2).forEach(image => {
        image.addEventListener('load', () => { collapse(); });
    });

    collapse();
}
